<template>
    <div>
        <table :style='{"width":"100%", "font-size":"7pt", "border-collapse": "collapse", "line-height": "normal"}'>
            <thead>
                <tr :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400"}}'>
                    <td
                        width="5%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Hora
                    </td>

                    <td
                        width="25%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Diagnósticos de enfermería
                    </td>
                    <td
                        width="10%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Resultados esperados
                    </td>
                    <td
                        width="60%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Intervenciones recomendaciones G.P.C.E.
                    </td>
                </tr>
            </thead>
            <tbody :style='{"vertical-align":"top", "white-space": "pre-wrap"}'>
                <tr v-for="(itm, id) in ((Object.keys(datos.diagnosticos).length)/3)" :key=id>
                    <td :style='{...estilos.tablaBorde, ...{"text-align":"center"}}'>{{diag[id].dateCreated.split(' ')[1].split(':')[0]}}</td>
                    <td :style='estilos.tablaBorde'>{{diag[id].contenido}}</td>
                    <td :style='estilos.tablaBorde'>{{res[id].contenido}}</td>
                    <td :style='estilos.tablaBorde'>{{int[id].contenido}}</td>
                </tr>
            </tbody>
        </table>
        <br/>
        <table :style='{"width":"100%", "font-size":"7pt", "border-collapse": "collapse", "line-height": "normal"}'>
            <thead>
                <tr :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400"}}'>
                    <td
                        width="5%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Hora
                    </td>

                    <td
                        width="73%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Resultados obtenidos
                    </td>
                    <td
                        width="22%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Códigos del GMGPC de las GPCE
                    </td>
                </tr>
            </thead>
            <tbody :style='{"vertical-align":"top", "white-space": "pre-wrap"}'>
                <tr v-for="(itm, id) in datos.resultados" :key="id">
                    <td :style='{...estilos.tablaBorde, ...{"text-align":"center"}}'>{{itm.dateCreated.split(' ')[1]}}</td>
                    <td :style='estilos.tablaBorde'>{{itm.resultado}}</td>
                    <td :style='estilos.tablaBorde'>{{itm.codigos}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],
    computed:{

        diag(){
            return this.datos.diagnosticos.filter(itm => itm.tipo == "diag")
        },
        res(){
            return this.datos.diagnosticos.filter(itm => itm.tipo == "res")
        },
        int(){
            return this.datos.diagnosticos.filter(itm => itm.tipo == "int")
        },
    },
}
</script>

<style>

</style>